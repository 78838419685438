<template>
    <div>
        <div :class="!isMobile ? 'mt-10' : 'text-left'">
            <span class="font-18 greytext--text">{{ $t("heroPage.yourType") }} </span>
        </div>

        <!-- content -->
        <div
                class="lightgreen radius-10 d-flex align-center justify-space-between px-4 py-2 mt-4"
                v-for="(type, i) in types"
                :key="i"
        >
            <!-- icon & title -->
            <div class="d-flex align-center text-left">
                <img :width="isMobile ? '30px' : '40px'" :src="type.icon" alt=""/>
                <div class="mx-3">
          <span class="font-21 font-900 greytext--text d-block">{{
              type.title
              }}</span>
                    <span class="font-16 lightgreytext--text d-block">{{
                        type.subTitle
                        }}</span>
                </div>
            </div>

            <!-- btn -->
            <div
                    class="white radius-10 d-flex align-center justify-center cursor-pointer hover-btn-outlined hero-btn"
                    :style="isMobile ? 'width: 100px' : 'width: 180px'"
                    @click="$router.push(type.route)"
            >
        <span class="font-700 font-18 register-primary--text">{{
            $t("heroPage.signup")
            }}</span>
            </div>
        </div>

        <div class="text-center mt-7">
            <p class="greytext--text font-18 d-flex align-center justify-center">
                {{ $t("heroPage.alreadyAccount") }}
                <router-link to="/register/login"
                ><span class="font-18 darkprimary--text cursor-pointer font-700 d-inline-block ml-1 mr-1"> {{
                    $t("heroPage.loginNow")
                    }}</span></router-link>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data: (vm) => ({
        types: [
            {
                title: vm.$t("heroPage.joinUs"),
                icon: require("@/assets/images/icons/doctor.svg"),
                subTitle: vm.$t("heroPage.asDoctor"),
                route: "/register/signup-doctor",
            },
            {
                title: vm.$t("heroPage.joinUs"),
                icon: require("@/assets/images/icons/hospital.svg"),
                subTitle: vm.$t("heroPage.asHospital"),
                route: "/register/signup-clinic",
            },
        ],
    }),
};
</script>

<style lang="scss">
.hero-btn {
  height: 50px;
}
</style>
